import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CanonicalService {

  constructor(
    @Inject(DOCUMENT) private dom: any,
    private router: Router,
  ) {}

  setCanonicalURL(url?: string) {
    const canURL = url === undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);

    this.updateCanonicalURL();
  }

  updateCanonicalURL() {
    let url: string;

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    )
    .subscribe(() => {
      if (this.dom.URL.indexOf('#') !== -1) {
        url = this.dom.URL.slice(0, this.dom.URL.lastIndexOf('#'));
      } else {
        url = this.dom.URL;
      }

      const canonicalLinkElement: HTMLLinkElement = this.dom.head.querySelector('link[rel="canonical"]');
      canonicalLinkElement.setAttribute('href', url);
    });
  }
}

